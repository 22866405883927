<template>
  <van-popup v-model="show" position="right" style="width:100%;height:100%" class="contain">
    <div class="con">
      <van-icon name="checked" color="#07C160" :size="$px2vw(180)"/>
      <div class="title">{{title}}</div>
      <div class="sub-title" v-if="subTitle">{{subTitle}}</div>
      <div class="btn" @click="confirm" v-if="confirmTxt">{{confirmTxt}}</div>
      <div class="link" v-if="linkTxt" @click="jump">{{linkTxt}}</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
        type: String,
        default: ''
    },
    subTitle: {
        type: String,
        default: '' 
    },
    confirmTxt: {
        type: String,
        default: ''
    },
    linkTxt: {
        type: String,
        default: ''
    },
    link: {
        type: Object,
        default: () => {}
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    jump() {
      if (this.link) {
        this.$router.push(this.link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contain{
  display: flex;
  align-items: center;
  justify-content: center;
}
.con{
  text-align: center;
  .title{
    margin-top: 40px;
    font-size: 48px;
    font-weight: bold;
    color: #4F4F4F;
  }
  .sub-title{
    margin-top: 10px;
    font-size: 32px;
    color: #666666;
  }
  .btn{
    margin-top: 60px;
    width: 574px;
    height: 88px;
    background: #1AA3FF;
    border-radius: 41px;
    line-height: 88px;
    text-align: center;
    color: #fff;
    font-size: 36px;
  }
  .link{
    margin-top: 30px;
    font-size: 32px;
    color: #1AA3FF;
    text-align: center;
  }
}
</style>