<template>
  <div class="container">
    <navbar title="上传付款凭证"></navbar>
    <div class="top-explain">请把自付货款 {{orderItem?.payDetail?.generalPayAmount}}元 汇到以下供应商账户
    </div>
    <div class="bank-account">
      <div class="account-line">
        <div class="account-label">银行</div>
        <div class="account-content">{{orderItem?.payDetail?.due_bank}}</div>
      </div>
      <div class="account-line">
        <div class="account-label">银行卡号</div>
        <div class="account-content">{{orderItem?.payDetail?.due_bank_card}}</div>
        <div
          class="account-operate"
          @click="$copy(orderItem?.payDetail?.due_bank_card)"
        >复制</div>
      </div>
      <div class="account-line">
        <div class="account-label">持卡人</div>
        <div class="account-content">{{orderItem?.payDetail?.due_bank_owner}}</div>
        <div
          class="account-operate"
          @click="$copy(orderItem?.payDetail?.due_bank_owner)"
        >复制</div>
      </div>
    </div>
    <div class="upload-title">上传付款凭证</div>
    <van-uploader
      v-model="fileList"
      :max-size="6 * 1024 * 1024"
      accept=".png, .jpg, .jpeg"
      class="upload-main"
      :upload-icon="'plus'"
      :preview-size="$px2vw(100)"
      :before-read="beforeRead"
      :after-read="afterRead"
      @oversize="onOversize"
      :max-count="1"
    />
    <div class="bottom-operate-placeholder"></div>
    <div class="bottom-operate">
      <div
        class="button button-1"
        @click="$router.push('/order/detail/'+orderItem.id)"
      >稍后再上传</div>
      <div
        class="button button-2"
        @click="save"
      >保存</div>
    </div>
    <success
      v-model="successShow"
      title="已成功上传付款凭证"
      subTitle="可在我的订单页面实时查看订单动态"
      confirmTxt="查看订单"
      @confirm="$router.push('/order/detail/'+orderItem.id+'?_replace=true')"
      linkTxt="返回商品详情"
      :link="{path:'/toy/detail/'+orderItem.toy_id+'?_replace=true'}"
    ></success>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import uploadFile from '@/lib/upload.js'
import Success from '@/components/success.vue';
import config from "@/config/index.js";
import { orderDetail, uploadVoucher } from "@/api/order.js";
import md5 from 'js-md5'
import { getRand } from "@/lib/utils.js";
export default {
  name: "payVoucher",
  components: {
    Navbar,
    Success,
  },
  inject: ['_app'],
  data() {
    return {
      config,
      tmpFilePath: '',
      file: {},
      fileList: [],
      successShow: false,
      orderId: null,
      orderItem: {},
      basePath: 'xiaoniao/jicai/voucher/'
    };
  },
  mounted() {
    if (this.$route?.params?.orderId) {
      this.orderId = this.$route.params.orderId;
      this.getOrderItem();
    }
  },
  methods: {
    getOrderItem() {
      orderDetail({ id: this.orderId }).then(res => {
        if (res.code == 0) {
          this.orderItem = res.data;
          if (this.orderItem['order_status'] != 0 || this.orderItem['payDetail']['payType'] != 2) {
            this.$toast({
              message: '订单状态不可上传凭证', onClose: () => {
                this.$back();
              }
            })
          }
        } else {
          this.$toast({
            message: res.msg, onClose: () => {
              this.$back();
            }
          });
        }
      })
    },
    beforeRead(file) {
      if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) == -1) {
        this.$toast('请上传 jpg,jpeg,png 格式的图片');
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.tmpFilePath = file.content;
      this.file = file;
    },
    onOversize(file) {
      this.$toast('文件大小不能超过 6M');
    },
    async upload() {
      let file = this.file.file;
      var fileName = this.basePath + md5(file.name + new Date().getTime()).substr(0, getRand(20, 14));
      if (file.type == "image/jpg" || file.type == "image/jpeg") {
        fileName += ".jpg";
      } else if (file.type == "image/png") {
        fileName += ".png";
      } else {
        fileName += ".jpg";
      }
      return await uploadFile(this.file.file, fileName)
    },
    async save() {
      if(JSON.stringify(this.file) == '{}'){
        this.$toast('请上传支付凭证');
        return false;
      }
      let data = {
        order_id: this.orderItem.id,
        generalPayId: this.orderItem.payDetail.generalPayId,
      };
      data['pay_voucher'] = await this.upload();
      if(!data['pay_voucher']){
        this.$toast('支付凭证上传失败');
      }else{
        uploadVoucher(data).then(res=>{
          if(res.code == 0){
            this._app.reloadPrev();
            this._app.reload('order');
            this.successShow = true;
          }else{
            this.$toast(res.msg);
          }
        })
      }
    }
  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 20px;
  min-height: 100vh;
  background: #ffffff;
  .top-explain {
    font-size: 30px;
    font-weight: 400;
    color: #4f4f4f;
    padding-top: 30px;
    font-weight: 400;
    color: #4f4f4f;
  }
  .bank-account {
    background: #f4f9fc;
    border-radius: 10px;
    padding: 30px;
    margin-top: 20px;
    .account-line {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      .account-label {
        width: 160px;
        flex-shrink: 0;
        font-size: 28px;
        font-weight: 400;
        color: #999999;
      }
      .account-content {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #666666;
      }
      .account-operate {
        flex-shrink: 0;
        font-size: 24px;
        font-weight: 400;
        color: #37afff;
      }
    }
  }
  .upload-title {
    font-size: 32px;
    font-weight: bold;
    color: #4f4f4f;
    margin: 20px 0;
  }
  .upload-main {
    :deep(.van-icon) {
      font-size: 24px;
      color: #c2c2c2;
    }
    :deep(.van-uploader__upload) {
      border: 1px dashed #c2c2c2;
      border-radius: 10px;
      margin-right: 30px;
      margin-bottom: 30px;
      background-color: #ffffff;
    }
    :deep(.van-uploader__preview) {
      border-radius: 10px;
      overflow: hidden;
      margin-right: 30px;
      margin-bottom: 30px;
    }
    :deep(.van-uploader__preview-delete) {
      width: 24px;
      height: 18px;
      border-radius: 0px 10px 0px 9px;
      background-color: rgba($color: #000000, $alpha: 0.28);
      display: flex;
      align-items: center;
      justify-content: center;
      .van-icon {
        color: #ffffff;
        position: static;
      }
    }
  }
  .bottom-operate-placeholder {
    @include safe-area-inset-bottom-height(148px);
  }
  .bottom-operate {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 60px);
    padding: 0 30px;
    height: 148px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include safe-area-inset-bottom;
    .button {
      width: 330px;
      height: 88px;
      border-radius: 44px;
      border: 1px solid #1aa3ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: 400;
    }
    .button-1 {
      color: #1aa3ff;
    }
    .button-2 {
      background: #1aa3ff;
      color: #ffffff;
    }
  }
}
</style>
